import React, {
  useContext, useEffect, useState,
} from "react";
import {
  StyledBeekeepers,
  StyledBeekeepersTitle,
  StyledContainerBeekeepers,
  StyledMapHeader,
  StyledMapHeaderContainer,
} from "./style";
import { useLocation } from "@reach/router";
import useApi from "../../hooks/useApi";
import BeekeepersList from "./BeekeepersList";
import { LayoutContext } from "../../contexts/LayoutContext";
import ApiErrorForm from "../ApiErrorForm/ApiErrorForm";
import { DataContext } from "../../contexts/DataContext";
import { StyledTitleHero, StyledSpacer, StyledStepText } from "../globalstyle";
import BeeCounter from "../BeeCounter/BeeCounter";
import { useForm } from "react-hook-form";
import MarketCountriesDropdown from "../MarketCountriesDropdown/MarketCountriesDropdown";

const Beekeepers = () => {
  const { languages, labels } = useContext(DataContext);
  const { getBeekeepers, getCountries } = useApi();
  const [beekeepers, setBeekeepers] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoadingBeekeepers, setIsLoadingBeekeepers] = useState(false);
  const [isMissingBeekeepers, setIsMissingBeekeepers] = useState(false);
  const { setModalContent } = useContext(LayoutContext);
  const location = useLocation();
  const form = useForm();
  const marketCountry = form.watch("marketCountry");

  const currentLanguage = languages.filter((language) => language.isCurrent);
  const currentLanguageCode = currentLanguage ? currentLanguage[0].label : "en";

  useEffect(() => {
    getCountries().then((_countries) => {
      setCountries(_countries.market_countries);

      form.setValue("marketCountry", _countries.market_countries.find(e => e.toLowerCase() === currentLanguageCode) ?? "IT");
    });
  }, [getCountries, form, currentLanguageCode]);

  // Get parameter from urls.
  useEffect(() => {
    const _urlParams = new URLSearchParams(location.search);
    const coupon = _urlParams.get("coupon");
    const honeyId = _urlParams.get("honey_id");
    const rare = _urlParams.get("rare");

    setIsLoadingBeekeepers(true);

    if (!marketCountry) {
      return;
    }
    
    getBeekeepers(honeyId, coupon, rare, currentLanguageCode, marketCountry, { onlyWithHoney: true }).then((res) => {
      if (res.error) {
        setModalContent(<ApiErrorForm message={res.message} />);
      } else {
        const _beekeepers = filterBeekeepers(res);
        setBeekeepers(_beekeepers);
        if (_beekeepers.length == 0) {
          setIsMissingBeekeepers(true);
        } else {
          setIsMissingBeekeepers(false);
          let beekeepersList = [];

          for (const i in _beekeepers) {
            beekeepersList.push({
              name: `${_beekeepers[i].fullname}`,
              id: `${_beekeepers[i].id}`,
              price: "28.00",
              brand: "www.3bee.com",
            });
          }
          if (typeof window !== "undefined") {
            window.dataLayer.push({
              event: "productViewBeekeepers",
              ecommerce: {
                idHoney: honeyId,
                currencyCode: "EUR",
                impressions: beekeepersList,
              },
            });
          }
        }

      }
      setIsLoadingBeekeepers(false);
    });
  }, [location, getBeekeepers, currentLanguageCode, setModalContent, marketCountry]);

  return (
    <StyledBeekeepers>
      <BeeCounter />
      <StyledSpacer size={8} />
      <StyledBeekeepersTitle>
        <StyledTitleHero>{labels.ADOPTTEXT}</StyledTitleHero>
        {marketCountry && (
          <MarketCountriesDropdown countries={countries} form={form} />
        )}
      </StyledBeekeepersTitle>
      <StyledSpacer size={8} />

      <StyledMapHeader>
        <StyledMapHeaderContainer>
          <StyledStepText>
            {labels.GO_TO_BEEKEEPER}
          </StyledStepText>
        </StyledMapHeaderContainer>
      </StyledMapHeader>

      <StyledContainerBeekeepers id="map">
        <BeekeepersList
          isLoading={isLoadingBeekeepers}
          items={beekeepers}
        />

        {isMissingBeekeepers && (
          <ApiErrorForm
            title={`${labels.BEEKEEPERS_NOT_FOUND}`}
            paragraph={labels.BEEKEEPERS_NOT_FOUND_PARAGRAPH}
            message={labels.BEEKEEPERS_NOT_FOUND}
            isFull
          />
        )}
      </StyledContainerBeekeepers>
      <StyledSpacer size={8} />
    </StyledBeekeepers>
  );
};

const filterBeekeepers = (beekeepers) => {
  /// Remove beekeepers without coordinates and order by ranking
  let beekeepersAll = beekeepers
    .filter((beekeeper) => (beekeeper.latitude != null || (beekeeper.hives.length > 0 && beekeeper.hives[0].latitude != null)))
    .sort((a, b) => b.ranking - a.ranking);
  
  /// Collect a maximum of [beekeepersPerRegion] beekeepers per region (All beekeeper if no region is set)
  const beekeepersPerRegion = 5;
  let regions = {};
  const _beekeepers = [];
  for (let i = 0; i < beekeepersAll.length; i++) {
    const region = beekeepersAll[i].geo_info.length > 0 ? beekeepersAll[i].geo_info[0].region : null;
    if (region != null) {
      regions[region] = ++regions[region] || 1;

      if (regions[region] <= beekeepersPerRegion) {
        _beekeepers.push(beekeepersAll[i]);
      }
    } else {
      _beekeepers.push(beekeepersAll[i]);
    }
  }

  /// Add more beekeepers until [maxBeekeepers] is reached
  const maxBeekeepers = 100;
  regions = {};
  for (let i = 0; i < beekeepersAll.length; i++) {
    const region = beekeepersAll[i].geo_info.length > 0 ? beekeepersAll[i].geo_info[0].region : null;
    if (region != null) {
      regions[region] = ++regions[region] || 1;

      if (regions[region] > beekeepersPerRegion && _beekeepers.length < maxBeekeepers) {
        _beekeepers.push(beekeepersAll[i]);
      }
    }
  }

  return _beekeepers;
};

export default Beekeepers;
